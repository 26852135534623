import React from 'react'
import { Header } from '../../../../Desktop/components/layouts/Header/Header'
import { Lugares } from '../../../../Desktop/components/ui/Lugares/Lugares'


export const PageLugares = () => {
  return (
    <div>
        <Header/>
        <Lugares/>
    </div>
  )
}
