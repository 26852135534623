import React from 'react'
import { Reserva } from '../../../../Desktop/components/ui/Reserva/Reserva'



export const RESERVAR = () => {
  return (
    <div className='Reserva1'>
          <Reserva/>
    </div>
  )
}