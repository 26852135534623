import React from 'react'
import { Persona } from '../Persona/Persona'

export const Personal = () => {
  return (
    <div className='personal'>
        <h1 className='titulopersonas'>PERSONAL</h1>
        <hr className='hrPersonal'/>
        <Persona/>
    </div>
  )
}
