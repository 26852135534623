import React from 'react'
import { Header } from '../../../../Desktop/componentsLogueado/layouts/Header/Header'
import { Lugares } from '../../../../Desktop/componentsLogueado/ui/Lugares/Lugares'


export const PageLugaresLogueado = () => {
  return (
    <div>
        <Header/>
        <Lugares/>
    </div>
  )
}
