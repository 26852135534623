import React from 'react'
import { Header } from '../../layouts/Header/Header'
import { Lugares } from '../../ui/Lugares/Lugares'


export const PageLugares = () => {
  return (
    <div>
        <Header/>
        <Lugares/>
    </div>
  )
}
