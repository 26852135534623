import React from 'react'
import { SobreNosotrosLayouts } from '../../layouts/SobreNosotrosLayouts/SobreNosotrosLayouts'


export const SobreNosotrosPage = () => {
  return (
    <div>
        <SobreNosotrosLayouts/>
    </div>
  )
}
