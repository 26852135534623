import React from 'react'
import { Info } from '../Info/Info'

export const Inicio = () => {
  return (
    <div className='inicio'>
        <Info />
    </div>
  )
}
