import React from 'react'
import { RecuperacionContraseña } from '../../../../Desktop/components/ui/RecuperacionContraseña/RecuperacionContraseña'

export const RecuperacionDeContraseña = () => {
  return (
    <div>
      <RecuperacionContraseña/>
    </div>
  )
}
