import React from 'react'
import { SobreNosotrosLayouts } from '../../../../Desktop/components/layouts/SobreNosotrosLayouts/SobreNosotrosLayouts'


export const SobreNosotrosPage = () => {
  return (
    <div>
        <SobreNosotrosLayouts/>
    </div>
  )
}
