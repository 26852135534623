import React from 'react'
import { RecuperacionContraseña } from '../../ui/RecuperacionContraseña/RecuperacionContraseña.jsx'

export const RecuperacionDeContraseña = () => {
  return (
    <div>
      <RecuperacionContraseña/>
    </div>
  )
}
