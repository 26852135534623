import React from 'react'
import { SobreNosotrosLayouts } from '../../../../Desktop/componentsLogueado/layouts/SobreNosotrosLayouts/SobreNosotrosLayouts'


export const SobreNosotrosPageLogueado = () => {
  return (
    <div>
        <SobreNosotrosLayouts/>
    </div>
  )
}
